<template>
  <section class="dashboard section is-full-touch is-full mobile">
    <div class="container is-fluid-desktop is-full-touch is-full-mobile">
      <div class="is-hidden-desktop is-hidden-widescreen">
        <Mobilemenu/>
      </div>
      <div class="columns">
        <div
            class="column is-narrow-desktop is-hidden-mobile is-hidden-touch sbar"
        >
          <Sidebar/>
        </div>
        <div class="column">
          <div class="block">
            <h1 class="title block">Members</h1>
            <h2 class="subtitle block">
              Manage members and member settings
            </h2>
          </div>
          <div class="block">
            <div class="field is-grouped">
              <div class="control">
                <button @click="purgeInactiveMembers()" class="button is-danger" disabled>Purge inactive members</button>
              </div>
              <div class="control">
                <button @click="purgeExpiredMembers()" class="button is-danger" disabled>Purge expired members</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import {db} from "@/firebase";
import Mobilemenu from "@/components/Mobilemenu";
import AccountService from "@/services/AccountService";

export default {
  name: "SettingsMembersView",
  components: {
    Sidebar,
    Mobilemenu
  },
  data() {
    return {
      // waitlist: null,
    }
  },
  // firestore: {
  //   waitlist: db.collection('settings').doc('waitlist')
  // },
  computed: {
    // waitlistStatus() {
    //   if (this.waitlist) {
    //     return this.waitlist.enabled ? 'Disable' : 'Enable'
    //   } else {
    //     return 'Error'
    //   }
    // }
  },
  methods: {
    async purgeInactiveMembers() {
      try {
        if (confirm('Are you sure you want to purge inactive members?')) {
          // const r = await AccountService.purgeInactiveMembers()
          // console.log(r)
        }
      } catch (e) {
        console.log(e)
      }
    },
    async purgeExpiredMembers() {
      try {
        if (confirm('Are you sure you want to purge expired members?')) {
          // const r = await AccountService.purgeExpiredMembers()
          // console.log(r)
        }
      } catch (e) {
        console.log(e)
      }
    },
  }
}
</script>
