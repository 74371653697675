<template>
  <section class="dashboard section is-full-touch is-full mobile">
    <div class="container is-fluid-desktop is-full-touch is-full-mobile">
      <div class="is-hidden-desktop is-hidden-widescreen">
        <Mobilemenu />
      </div>
      <div class="columns">
        <div
            class="column is-narrow-desktop is-hidden-mobile is-hidden-touch sbar"
        >
          <Sidebar />
        </div>
        <div class="column">
          <div class="block">
            <h1 class="title block">Waitlist</h1>
            <h2 class="subtitle block">
              Enable or disable waitlist and registration code here
            </h2>
          </div>
          <div class="block">
            <button @click="toggleWaitlist()" class="button is-primary">{{ waitlistStatus }} waitlist</button>
          </div>
          <div v-if="waitlist" class="plan box block">
            <div class="field">
              <label class="label">Registration closed text</label>
              <div class="control">
                <input
                    v-model="waitlist.registrationClosedText"
                    class="input"
                    placeholder="Text input"
                    type="text"
                />
              </div>
            </div>

            <div class="field is-grouped">
              <div class="control">
                <button @click="updateRegistrationText" class="button is-link">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import {db} from "@/firebase";
import Mobilemenu from "@/components/Mobilemenu";
import AccountService from "../services/AccountService";

export default {
  name: "SettingsWaitlistView",
  components: {
    Sidebar,
    Mobilemenu
  },
  data() {
    return {
      waitlist: null,
    }
  },
  firestore: {
    waitlist: db.collection('settings').doc('waitlist')
  },
  computed: {
    waitlistStatus() {
      if (this.waitlist) {
        return this.waitlist.enabled ? 'Disable' : 'Enable'
      } else {
        return 'Error'
      }
    }
  },
  methods: {
    async toggleWaitlist() {
      try {
        await this.$firestoreRefs.waitlist.update({
          enabled: !this.waitlist.enabled
        })
        alert('Updated');
      } catch (e) {
        console.log(e)
      }
    },
    async updateRegistrationText() {
      try {
        await this.$firestoreRefs.waitlist.update({
          registrationClosedText: this.waitlist.registrationClosedText
        })
        alert('Updated');
      } catch (e) {
        console.log(e)
      }
    },
  }
}
</script>
